import { GettingStartedData } from './displayViews.model'
import Image from 'next/image'
import USBButton from '@usb-shield/react-button'
import USBLink from '@usb-shield/react-link'
import { USBColumn } from '@usb-shield/react-grid'
import { defaultColLayoutOptions } from '@/utils/usb-grid.util'
import styles from './displayViews.module.scss'

const contentBlock: Object = {
  ...defaultColLayoutOptions,
  spans: {
    xlarge: 3,
    large: 3,
    medium: 2,
    small: 4,
  },
}

const GetStartedView = ({
  ctaText,
  ctaLink,
  iconImage,
  linkAsButton,
}: GettingStartedData) => {
  return (
    <>
      <USBColumn layoutOpts={contentBlock} addClasses={styles.gsWrapper}>
        <div className={styles.gsImageWrapper}>
          <img
            src={iconImage}
            alt=""
            data-testid={`iconImage`}
          />
        </div>

        {linkAsButton == true ? (
          <USBButton
            ctaStyle="standard"
            emphasis="heavy"
            size="medium"
            addClasses={styles.buttonGetStarted}
            handleClick={() => {
              window.open(ctaLink, '_self')
            }}
          >
            {ctaText}
          </USBButton>
        ) : (
          <USBLink
            linkType="arrow"
            href={ctaLink}
            addClasses={styles.ctaLink}
            dataTestId={`tile-ctaLink`}
          >
            {ctaText}
          </USBLink>
        )}
      </USBColumn>
    </>
  )
}

export default GetStartedView
